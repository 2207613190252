/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./components/Layouts/Base";
import Privacy from "./pages/Privacy";
import WhitePaper from "./pages/WhitePaper";
import Roadmap from "./pages/Roadmap";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Guess from "./pages/Guess";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className='App'>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/guess' element={<Guess />} />
            {/* <Route path="/privacy-policy" element={<Privacy/>} />
          <Route path="/white-paper" element={<WhitePaper/>} />
          <Route path="/roadmap" element={<Roadmap/>} /> */}
            {/* <Route path="/*" element={<PageNotFound />}/> */}
          </Routes>
        </Base>
      </BrowserRouter>
    </div>
  );
}

export default App;
