/** @format */

import React, { useState } from "react";
import copy from "clipboard-copy";
import { IoIosPlay } from "react-icons/io";
import { TiArrowSortedDown } from "react-icons/ti";

const faqs = [
  {
    id: 1,
    question: "1) Set up a wallet:",
    answer: `Sign up to join and integrate your digital wallet. This sets the base for storing,
    sending, and receiving simSOL coins.`,
  },
  {
    id: 2,
    question: "2) Send & Receive simSOL coins:",
    answer: `You can send and receive coins from your fellow creators as means of appreciation
    for the meme.`,
  },
  {
    id: 3,
    question: "3) Manage Transactions:",
    answer: `The wallet provides you all the information and your transaction history. This feature
    ensures visibility and complete ownership of your rewards.`,
  },
  {
    id: 4,
    question: "4) Withdrawal:",
    answer: `This platform provides flexibility to withdraw your coins to external wallets and
    exchanges ensuring the culture of complete control and freedom. 

In the end of page we need to put contact information, blogs link, and
documentation.
  `,
  },
];

const Sections = () => {
  const [showAnswer, setShowAnswer] = useState({});

  const toggleAnswer = id => {
    setShowAnswer(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleClick = () => {
    const textToCopy = "Bkg7Adn4h7tn7KzddXqmXmcF7u9j39CiczSVE1D9i7WN";
    copy(textToCopy);
    alert("Contract address copied to clipboard");
  };
  return (
    <>
      <div className='bg-[#FED420] lg:py-28'>
        <div className='container'>
          <h4 className='text-center lg:text-3xl text-[#000] font-custom font-bold'>
            Total Supply{" "}
          </h4>
          <h1 className='text-[#000] font-custom lg:text-4xl text-center text-2xl font-bold my-3 '>
            1,000,000,000{" "}
          </h1>
          <div className='flex justify-center '>
            <button
              className='text-center border-2 px-6 py-1 rounded-md border-white text-white font-rubik font-semibold lg:text-lg text-[8px] cursor-pointer hover:underline'
              onClick={handleClick}>
              SOL: <br /> Bkg7Adn4h7tn7KzddXqmXmcF7u9j39CiczSVE1D9i7WN
            </button>
          </div>
          <div className='flex justify-center items-center py-3'>
            <img
              src='assets/images/solaba-box.png'
              className='w-16 shadow-image'
              alt=''
            />
          </div>
          <h4 className='text-center lg:text-3xl text-[#000] font-custom font-bold'>
            Getting Started:{" "}
          </h4>

          {faqs.map(faq => (
            <div
              key={faq.id}
              className='flex flex-col justify-center items-center py-2'>
              <div
                onClick={() => toggleAnswer(faq.id)}
                className='flex justify-center items-center bg-[#000] cursor-pointer lg:w-2/4 w-96 shadow-image rounded-md'>
                <p className='text-xs font-monospace text-center py-2 m-0 flex-grow text-white'>
                  {faq.question}
                </p>
                {showAnswer[faq.id] ? (
                  <TiArrowSortedDown className='mx-2' />
                ) : (
                  <IoIosPlay className='mx-2' />
                )}
              </div>
              {showAnswer[faq.id] && (
                <div className='flex my-1 justify-center items-center border cursor-pointer lg:w-2/4 w-96 shadow-image rounded-md'>
                  <p className='text-xs font-monospace text-center py-2 px-2 m-0 flex-grow text-black'>
                    {faq.answer}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sections;
