/** @format */

import React from "react";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Roadmap = () => {
  return (
    <>
      <div className='bg-[#000]'>
        <div className='container'>
          <div className='flex flex-col justify-center items-center '>
            <h1 className='text-[#FED420] font-custom lg:text-6xl text-4xl font-bold my-3 '>
              Making meme:
            </h1>
            <img
              src='assets/images/m1.png'
              data-aos='fade-up'
              className='rounded-3xl lg:w-2/5 '
              alt=''
            />
          </div>
          <div className='flex justify-center items-center py-10 space-x-3'>
            <button
              data-aos='zoom-in'
              data-aos-duration='1000'
              className='font-rubik uppercase text-[#000] py-2 rounded-md border-[3px] border-white hover:bg-[#1d1e20] hover:text-[#fff] bg-[#FED420] w-48'>
              road map
            </button>
            <button
              data-aos='zoom-in'
              data-aos-duration='1000'
              className='font-rubik uppercase text-[#000] py-2 rounded-md border-[3px] border-white hover:bg-[#1d1e20] hover:text-[#fff] bg-[#FED420] w-48'>
              Tokenomics{" "}
            </button>
          </div>
          <div className='flex justify-center '>
            <img src='assets/images/m3.png' className='lg:w-1/5' alt='' />
          </div>
          <div className='flex justify-center items-center '>
            <a
              href='https://t.me/Simsolofficial'
              target='_blank'
              title='Join us on Telegram!'
              rel='noreferrer'>
              <button
                data-aos='flip-up'
                data-aos-duration='1000'
                className='font-rubik capitalize text-[#fff] py-2 rounded-md border-[3px] border-white hover:bg-[#1d1e20] hover:text-[#fff] bg-[none] w-48'>
                Join on Telegram{" "}
              </button>
            </a>
          </div>
          <h4 className='text-center text-[#fff] font-bold font-rubik text-lg py-2 m-0'>
            contact@simsol.fun
          </h4>
          <div className='py-2'>
            <div className='flex justify-center items-center space-x-10'>
              <a
                href='https://t.me/Simsolofficial'
                target='_blank'
                rel='noreferrer'
                className=' cursor-pointer '>
                <FaTelegramPlane
                  color='#FED420'
                  size={40}
                  className='mx-2 my-1'
                />
              </a>
              <a
                href='https://twitter.com/Simsolofficial'
                target='_blank'
                rel='noreferrer'
                className=' cursor-pointer '>
                <FaTwitter color='#FED420' size={40} className='mx-2 my-1' />
              </a>
            </div>
            <p className='font-rubik text-md text-center mt-4 text-white'>
              Memes are one the best ways to feel belonged and relatable on the
              internet. A good meme should be funny and inspired from the
              environment. People on the internet have set of believes, emotions
              and feelings and they need to be heard. That’s where your
              creativity comes in. You need to find a creative expression to
              those inner thoughts and behaviors that never made it to the
              mainstream.
            </p>
            <p className='font-rubik text-md text-center text-white'>
              <span className='text-white font-semibold'>
                Meme provides you an opportunity to do that.
              </span>
              At simSOL, a good meme doesn’t just communicate the behaviors and
              feelings but form the basic of cryptocurrency. Plus, it makes
              people laugh and feel connected. It shapes the community that we
              have at simSOL
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
