import React from 'react'
import Hero from '../components/Elements/Home/Hero'
import Partner from '../components/Elements/Home/Partner'
import Tokenomics from '../components/Elements/Home/Tokenomics'
import Sections from '../components/Elements/Home/Sections'
import Roadmap from '../components/Elements/Home/Roadmap'

const Home = () => {
  return (
    <>
      
      <Hero/>
      <Partner/>
      <Tokenomics/>
      <Sections/>
      <Roadmap/>
    </>
  )
}

export default Home