/** @format */

import React, { useEffect, useState } from "react";

const Guess = () => {
  const initialAnswers = ["homer", "marge", "maggie", "bart", "lisa", "moe"];
  const [answers, setAnswers] = useState(initialAnswers);
  const [collectedAnswers, setCollectedAnswers] = useState(
    new Array(6).fill(""),
  );
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [allInputsComplete, setAllInputsComplete] = useState(false);
  const [tweeted, setTweeted] = useState(false);
  useEffect(() => {
    shuffleAnswers();
  }, []);

  const shuffleAnswers = () => {
    const shuffledAnswers = [...initialAnswers].sort(() => Math.random() - 0.5);
    setAnswers(shuffledAnswers);
  };

  const passAnswers = () => {
    const newCorrectAnswers = [];
    let allInputsFilled = true;
    for (let i = 0; i < answers.length; i++) {
      if (!collectedAnswers[i]) {
        allInputsFilled = false;
        break;
      }
      if (collectedAnswers[i].toLowerCase() === answers[i]) {
        newCorrectAnswers.push(answers[i]);
      } else {
        newCorrectAnswers.push(null);
      }
    }
    setAllInputsComplete(allInputsFilled);
    setCorrectAnswers(newCorrectAnswers);

    if (newCorrectAnswers.length === 6 && allInputsFilled && !tweeted) {
      setTweeted(true);
      shareOnTwitter();
    }
  };

  const shareOnTwitter = () => {
    const tweetText =
      "I finished playing the Guess of SimSol and wanted to share it with you for some awesome benefits. Don't miss out on simsol.fun and join in for exciting rewards!";
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText,
    )}&hashtags=Simsol,SolanaMemeCoins,Simsolmeme,Airdrop`;
    window.open(tweetUrl, "_blank");
  };

  const handleInputChange = (index, event) => {
    const newCollectedAnswers = [...collectedAnswers];
    newCollectedAnswers[index] = event.target.value;
    setCollectedAnswers(newCollectedAnswers);
  };

  return (
    <div className=' guess bg-[#000]'>
      <div className='bg-black container-fluid'>
        {/*Simpsons Game */}
        <h1 className='text-[#FED420] font-custom lg:text-6xl text-4xl font-bold  text-center'>
          The SimpSol...
        </h1>

        <p className='font-rubik text-xl  mt-4 text-white text-center font-bold italic'>
          Guess each character's <span className='text-[#FED420]'>FIRST</span>{" "}
          name
        </p>

        {[0, 1, 2, 3, 4, 5].map(index => (
          <div key={index} className='spacing'>
            <h1 id={"name" + index} className=' font-custom text-3xl'>
              {correctAnswers[index] ? answers[index] : "***"}
            </h1>{" "}
            <img
              id={"pic" + index}
              src={`https://raw.githubusercontent.com/sbjacobs231/The-Simpsons-Guess-Who-Game/master/img/${
                correctAnswers[index] ? answers[index] : answers[index] + "blur"
              }.jpg`}
              alt=''
            />
            <input
              type='text'
              id={"answer" + index}
              list={"suggestions" + index} // Use a datalist for suggestions
              onChange={event => handleInputChange(index, event)}
            />
            <datalist id={"suggestions" + index}>
              {" "}
              {answers.map((answer, idx) => (
                <option key={idx} value={answer} />
              ))}
            </datalist>
          </div>
        ))}

        <div className='buttonwrap pb-5'>
          <button
            id='btn'
            type='button'
            // disabled={!allInputsComplete}
            title={
              allInputsComplete
                ? "Submit your answers!"
                : "Submit your answers and see who you matched with!"
            }
            onClick={passAnswers}
            className='brightness-150 dark:brightness-100 group hover:shadow-lg hover:shadow-yellow-700/60 transition ease-in-out hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed p-1 rounded-xl bg-gradient-to-br from-yellow-800 via-yellow-600 to-yellow-800 hover:from-yellow-700 hover:via-yellow-800 hover:to-yellow-600'>
            <div className='px-6 py-2 backdrop-blur-xl bg-black/80 rounded-lg font-bold w-full h-full'>
              <div className='group-hover:scale-100 flex group-hover:text-yellow-500 text-yellow-600 gap-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.8'
                  className='w-6 h-6 stroke-yellow-600 group-hover:stroke-yellow-500 group-hover:stroke-{1.99}'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z'
                  />
                </svg>
                Submit
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Guess;
