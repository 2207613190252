/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <>
      <div className='bg-[#000]'>
        <div className='container'>
          <div className='flex justify-start'>
            <h1 className='text-[#FED420] font-custom lg:text-6xl text-4xl font-bold my-3 text-center m-auto'>
              What is simSOL?{" "}
            </h1>
          </div>
          <div className='grid lg:grid-cols-2'>
            <div className='m-auto'>
              <p className='font-rubik text-xl text-start mt-4 text-white'>
                Remember the wild enthusiasm around meme coins? Doge, Shiba,
                Pepe – Sounds familiar? Trends come and go and flip the dominant
                faces of the internet. A new champion is ready to take the
                internet by storm!
                <br />
                <br />
                Introducing simSOL, the emerging trend of the meme coin
                universe! Built on the Solana network, simSOL is destined to
                leave the competition in the dust.
                <br />
                <br />
                Simsol boasts a unique advantage: zero transaction fees! Plus, a
                passionate community thriving on fun, makes SimSOL, a potential
                platform of laughter and financial capital.
              </p>

              <h3 className='text-[#FED420] font-custom lg:text-4xl font-semibold my-3'>
                Create Value Every Day:{" "}
              </h3>
              <p className='font-rubik text-xl text-start mt-4 text-white'>
                <strong>How you can create value every day? </strong> It’s as
                simple as being kind and as creative as making a meme. That’s
                perfectly summed-up through our tagline – Create Value Every
                Day!
              </p>
              {/* <div className="flex justify-end floating-object2">
                    <img src="assets/images/100msupplyblack.png" className="w-2/6"   alt="" />
                    </div> */}
              {/* <div className="flex justify-end ">
                    <img src="assets/images/token.png"  alt="" />
                    </div> */}
            </div>
            <div className='floating-object   flex justify-center items-center pt-5 ml-20'>
              <img
                src='assets/images/m2.png'
                className='w-3/5 m-auto '
                alt=''
              />
            </div>
          </div>

          <div className='border-t-2 border-[#FED420] border-dashed py-5' />
          <div className='grid lg:grid-cols-2 pb-5'>
            <div className='floating-object   flex justify-center items-center  ml-20'>
              <img
                src='assets/images/m4.png'
                className='w-3/5 m-auto '
                alt=''
              />
            </div>
            <div className='m-auto'>
              <h3 className='text-[#FED420] font-custom lg:text-4xl font-semibold my-3'>
                How does it work?{" "}
              </h3>
              <p className='font-rubik text-xl text-start  text-white'>
                Let your imagination to turn you crazy and find your spot by
                creating a meme using our meme generation tool. It can be a
                humorous caption, image or funny meme in your mind.
                <br />
                <br />
                Community vibe with your meme by sharing with the broader
                community of simSOL.
                <br />
                <br />
                Get acknowledged by the community not just by likes but simSOL
                coin. That’s how we reward the creativity.
                <br />
                <br />
                Get involved in the marketplace and trade your product.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tokenomics;
