/** @format */

import React from "react";
import CountDown from "./CountDown";

const Hero = () => {
  return (
    <>
      <div className='bg-[#000]'>
        <div className='container-fluid '>
          <div className='grid lg:grid-cols-1 lg:pt-4 pb-5'>
            <div className='flex flex-col justify-center items-center text-center'>
              <div className='flex flex-wrap justify-center  lg:max-w-6xl items-center pb-3'>
                <a
                  href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=Bkg7Ad&outputCurrency=Bkg7Adn4h7tn7KzddXqmXmcF7u9j39CiczSVE1D9i7WN&fixed=in'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='lg:w-2/6 '>
                  <img
                    src='assets/images/p2.png'
                    className=' w-full shadow-image'
                    alt=''
                  />
                </a>
                <img
                  src='assets/images/hero1.png'
                  className='w-2/5 m-auto'
                  alt=''
                />
                <a
                  href='https://www.dexview.com/solana/Bkg7Adn4h7tn7KzddXqmXmcF7u9j39CiczSVE1D9i7WN'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='lg:w-1/5 '>
                  <img
                    src='assets/images/p4.png'
                    className='w-full shadow-image'
                    alt=''
                  />
                </a>
              </div>
              {/* <div className='floating-object mt-2'></div> */}
              {/* <CountDown /> */}
              <h3 className='text-[#FED420]  font-custom lg:text-2xl font-semibold  animate-pulse duration-300'>
                $SimSol is Live on Raydium
              </h3>
              <a
                href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=Bkg7Ad&outputCurrency=Bkg7Adn4h7tn7KzddXqmXmcF7u9j39CiczSVE1D9i7WN&fixed=in'
                target='_blank'
                title='Buy $simSOL!'
                rel='noreferrer'
                className='font-custom lg:text-xl block animate-bounce  text-black hover:text-[#fff] no-underline max-w-xs m-auto  bg-[#FED420] border-[1px] hover:bg-[#FED420ca] my-2 px-2 py-2 rounded-lg'
                style={{ borderColor: "#000" }}>
                Trade on Raydium
              </a>
              <div data-aos='fade-up'>
                <h3 className='text-[#FED420]  font-custom lg:text-4xl font-semibold my-3'>
                  The humor driven crypto community.{" "}
                </h3>
                {/* <strong className="text-[#FED420] font-custom lg:text-6xl text-4xl font-bold my-3 ">
                The SimSol
              </strong> */}
                {/* <h4 className="text-white font-custom lg:text-4xl font-semibold my-2">
                On a crypto DEX
              </h4> */}
                <p className='text-white font-custom lg:text-xl font-semibold my-3 max-w-xl m-auto'>
                  Ever wondered where does humor come from and what impact it
                  plays to connect the community?{" "}
                </p>
                <p className='text-white font-custom lg:text-xl font-semibold my-3 max-w-xl m-auto'>
                  Brace yourself for meme driven creation backed by creativity,
                  innovation thrives and humor lies at the heart of it.{" "}
                </p>
                <h3 className='text-[#FED420] font-custom lg:text-5xl text-4xl font-bold mt-2 mb-4'>
                  $Simsol
                </h3>
                <a
                  href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=Bkg7Ad&outputCurrency=Bkg7Adn4h7tn7KzddXqmXmcF7u9j39CiczSVE1D9i7WN&fixed=in'
                  target='_blank'
                  title='Buy $simSOL!'
                  rel='noreferrer'
                  className='font-custom lg:text-xl block animate-bounce  text-black hover:text-[#fff] no-underline max-w-xs m-auto  bg-[#FED420] border-[1px] hover:bg-[#FED420ca] my-3 px-2 py-2 rounded-lg'
                  style={{ borderColor: "#000" }}>
                  Trade on Raydium
                </a>
                <a
                  href='https://t.me/SimSol_bot'
                  target='_blank'
                  title='SimSol Telegram Trading Bot'
                  rel='noreferrer'
                  className='font-custom lg:text-xl text-black hover:text-[#fff]  no-underline  bg-[#FED420] border-[1px] hover:bg-[#FED420ca] my-3 px-2 py-2 rounded-lg'
                  style={{ borderColor: "#000" }}>
                  Trading Bot
                </a>
                <a
                  href='https://play.simsol.fun/'
                  rel='noreferrer'
                  target='_blank'
                  className='font-custom lg:text-xl ml-3
                  text-black hover:text-[#fff] no-underline bg-[#FED420]
                  border-[1px] hover:bg-[#FED420ca] my-3 px-2 py-2 rounded-lg'
                  style={{ borderColor: "#000" }}
                  title='Play SimSol Game on Web!'>
                  Play Game
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
