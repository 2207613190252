/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <nav className=' px-4 py-2 bg-[#000]  '>
        <div className='container-fluid mx-auto flex justify-between items-center'>
          <div className='flex justify-start items-center  gap-2 text-[#FED420] font-custom text-4xl font-bold'>
            <img
              src='assets/images/logo.png'
              className='lg:w-24 w-1/4 '
              alt=''
            />
            SimSol
          </div>
          <div className='hidden md:flex  justify-center md:justify-center space-x-6 '>
            <Link
              to='/'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  font-bold text-md no-underline capitalize flex items-center gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='currentColor'
                className='bi bi-house inline-block'
                viewBox='0 0 16 16'>
                <path d='M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z' />
              </svg>
              home
            </Link>
            <Link
              to='/guess'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  font-bold text-md no-underline capitalize flex items-center gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='currentColor'
                className='bi bi-patch-question inline-block'
                viewBox='0 0 16 16'>
                <path d='M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745' />
                <path d='m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z' />
                <path d='M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0' />
              </svg>
              Guess
            </Link>
            <a
              href='https://play.simsol.fun/'
              target='_blank'
              rel='noreferrer'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  font-bold text-md no-underline capitalize flex items-center gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='currentColor'
                className='bi bi-controller inline-block'
                viewBox='0 0 16 16'>
                <path d='M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1z' />
                <path d='M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729q.211.136.373.297c.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466s.34 1.78.364 2.606c.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527s-2.496.723-3.224 1.527c-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.3 2.3 0 0 1 .433-.335l-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a14 14 0 0 0-.748 2.295 12.4 12.4 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.4 12.4 0 0 0-.339-2.406 14 14 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27s-2.063.091-2.913.27' />
              </svg>
              Game
            </a>
            <a
              href='https://t.me/SimSol_bot'
              target='_blank'
              rel='noreferrer'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  font-bold text-md no-underline capitalize flex items-center gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='currentColor'
                className='bi bi-robot inline-block'
                viewBox='0 0 16 16'>
                <path d='M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135' />
                <path d='M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5' />
              </svg>
              TG Bot
            </a>
            <a
              href='https://www.pinksale.finance/solana/launchpad/HFnSHNirbsBAMhC3V3iimTNjmE3r82CY8EBEdtPbzG4s'
              target='_blank'
              rel='noreferrer'
              className='hover:text-[#fff] cursor-pointer text-[#FED420] animate-pulse font-rubik  font-bold text-md no-underline capitalize flex items-center gap-2'>
             Claim
            </a>
          </div>
          {/* <div className='hidden md:flex  justify-center md:justify-center space-x-6 '>
            <Link
              to='/'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  text-md no-underline capitalize'>
              home
            </Link>
            <Link
              to='/privacy-policy'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  text-md no-underline capitalize'>
              privacy policy
            </Link>
            <Link
              to='/white-paper'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  text-md no-underline capitalize'>
              white paper
            </Link>
            <Link
              to='/roadmap'
              className='text-[#fff] cursor-pointer hover:text-[#FED420] font-rubik  text-md no-underline capitalize'>
              road map
            </Link> */}
          {/* </div> */}
          <div className='lg:flex justify-between items-center hidden'>
            <a
              href='https://t.me/Simsolofficial'
              target='_blank'
              rel='noreferrer'
              className=' cursor-pointer '>
              <FaTelegramPlane
                color='#FED420'
                size={22}
                className='mx-2 my-1'
              />
            </a>
            <a
              href='https://twitter.com/Simsolofficial'
              target='_blank'
              rel='noreferrer'
              className=' '>
              <FaTwitter color='#FED420' size={22} className='mx-2 my-1' />
            </a>
            <a
              href='https://coinsult.net/projects/simsol/'
              target='_blank'
              rel='noreferrer'
              className=' '>
              <img
                src='assets/images/cslogo.svg'
                className='lg:w-24 w-2/4 '
                alt=''
              />
            </a>
          </div>
          <div className='md:hidden '>
            {/* Mobile menu button (Hamburger icon) */}
            <button className='text-[#FED420]' onClick={toggleMenu}>
              <svg
                className='h-6 w-6'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path d='M4 6h16M4 12h16m-7 6h7'></path>
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className='md:hidden w-96'>
            <div className='flex flex-col text-center space-y-2 py-3 rounded-md bg-[#000]'>
              <Link
                to='/'
                className='text-[#fff] font-rubik font-medium no-underline capitalize'>
                home
              </Link>
              <Link
                to='/guess'
                className='text-[#fff] font-rubik font-medium no-underline capitalize'>
                Guess Game
              </Link>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
