import React from 'react'

const Footer = () => {
  return (
    <>
      <footer
        className="footer text-center text-lg-start text-white"
        style={{ backgroundColor: "#172026" }}
      >
       
      </footer>
    </>
  )
}

export default Footer