import React from "react";

const Partner = () => {
  return (
    <>
      <div className="bg-[#FED420] pb-5">
        <div className="container">
            <div className="flex justify-center items-center">
            <img src="assets/images/partners.png" className="lg:w-2/4 shadow-image " alt="" />
            </div>
            <div className="flex flex-wrap justify-center items-center pb-3">
                <img src="assets/images/cslogo.svg" className="lg:w-1/5 shadow-image" alt="" />
                <img src="assets/images/p2.png" className="lg:w-2/5 shadow-image" alt="" />
                <img src="assets/images/p3.png" className="lg:w-2/5 shadow-image" alt="" />
                <img src="assets/images/p4.png" className="lg:w-2/5 shadow-image" alt="" />
                <img src="assets/pinksale-1.png" className="lg:w-2/5 shadow-image" alt="" />
                <img src="assets/images/solscan.svg" className="lg:w-2/5 shadow-image" alt="" />
            </div>
        </div>
      </div>
    </>
  );
};

export default Partner;
